"use client";

import { useSession } from "next-auth/react";
import { ofetch } from "ofetch";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

import { cn } from "@/helpers/className";
import Icon from "../atoms/Icon";

const fetcher = async (url: string) => {
  return await ofetch(url);
};

async function updateSavedJob(
  url: string,
  {
    arg,
  }: {
    arg: string[];
  },
) {
  return await ofetch<string>(url, {
    method: "POST",
    body: {
      jobIds: arg,
    },
  });
}

interface SaveJobButtonProps {
  className?: string;
  jobId: string;
}

export const SaveJobButton = ({ jobId, className }: SaveJobButtonProps) => {
  const { data: session } = useSession();

  const {
    data,
    error,
    isLoading,
  }: { data: string[]; error: Error | undefined; isLoading: boolean } = useSWR(
    session ? `${process.env.NEXT_PUBLIC_BASE_URL}/api/user/saved-jobs` : null,
    fetcher,
  );
  const { trigger } = useSWRMutation(
    session ? `${process.env.NEXT_PUBLIC_BASE_URL}/api/user/saved-jobs` : null,
    updateSavedJob,
  );

  if (!session) {
    return <></>;
  }

  const isSaved = data?.includes(jobId);

  const toggleSaveJob = async (jobId: string) => {
    let newSavedJobIds: string[] = [];

    if (!isSaved) {
      // Add new job to the start of the array
      newSavedJobIds = [jobId, ...(data ?? [])];
    } else {
      newSavedJobIds = data?.filter((id) => id !== jobId) ?? [];
    }

    await trigger(newSavedJobIds, {
      optimisticData: newSavedJobIds,
      rollbackOnError(error: Error) {
        // If it's timeout abort error, don't rollback
        return error.name !== "AbortError";
      },
    });
  };

  // In case of an error, just hide the button.
  if (error ?? !session) return <></>;

  return (
    <button
      type="button"
      onClick={() => toggleSaveJob(jobId)}
      className={cn(
        "relative top-1 z-20 -m-1 shrink-0 p-1 transition-all duration-200 hover:scale-110 hover:text-yellow-600",
        isSaved ? "text-yellow-600" : "text-black-300",
        isLoading && "animated-pulse",
        className,
      )}
    >
      <Icon name={isSaved ? "love-fill" : "love"} className="h-6 w-6" />
    </button>
  );
};

export default SaveJobButton;
